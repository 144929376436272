<template>
  <div class="d-inline-block">
    <span class="d-flex-center cursor-pointer"
          :class="[variant, iconClasses]"
          ref="tooltipTarget"
          @click="!allowClickToBubble ? $event.stopPropagation() : null">
      <fluency-icon :type="icon" :size="iconSize" />
    </span>
    <tasty-popover :target="tooltipTarget"
               triggers="hover focus"
               :boundary="boundary"
               :container="container"
               :placement="placement"
               :custom-class="`white-space-pre-line ${customPopoverClass}`">
      <slot name="title"><span v-html="title"/></slot>
    </tasty-popover>
  </div>
</template>
<script setup>
import { ref } from 'vue'

defineProps({
  title: String,
  icon: {
    type: String,
    default: 'info'
  },
  iconSize: {
    type: String,
    default: 'md'
  },
  iconClasses: {
    type: String
  },
  variant: {
    type: String
  },
  popover: { // use a popover style tooltip instead of a tooltip style
    type: Boolean,
    default: false
  },
  boundary: {
    type: [String, Object, HTMLElement]
  },
  container: {
    type: [String, Object, HTMLElement]
  },
  placement: {
    type: String
  },
  customPopoverClass: {
    type: String,
    default: () => ''
  },
  allowClickToBubble: {
    type: Boolean,
    default: false
  }
})

const tooltipTarget = ref(null)

</script>

<style lang='scss' scoped>

</style>
